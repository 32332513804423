import { useState } from "react";
import NoImage from "assests/images/No-image.png";

import Component from "./Popup";
import DemandSection from "./Popup/DemandSection";
import Eye from "shared/icons/Eye";

import capsule from "assests/images/capsule.png";

const SearchItem = ({ idKey, articleInfo, options, currencyRate, currency }) => {
    const [randNum] = useState((Math.random() * 3.8 + 1).toFixed(1));

    return (
        <div
            key={idKey}
            className="flex items-center justify-between w-full min-w-[101vw] lg:min-w-[721px] pt-2 pr-1 pb-0 lg:pt-7 md:max-w-2xl"
        >
            <div className="flex items-center">
                <div className="flex flex-col ">
                    <div className="flex items-center lg:justify-between max-w-[620px] mb-1">
                        <div className="flex flex-col relative w-full pl-2">
                            <div className="flex items-center mb-1 relative">
                                <div
                                    className="border rounded overflow-hidden min-w-[62px] h-[31px] lg:min-w-[85px] lg:h-[40px] mr-2"
                                    style={{
                                        backgroundImage: `url(${articleInfo.thumbnail || NoImage})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                    }}
                                ></div>
                                <div className="flex absolute left-[70px] lg:left-[90px] items-center justify-start text-xs lg:text-xs mb-1 text-black">
                                    <p>1h</p>
                                    <span className="px-0.5 -mt-5 text-3xl text-[#767778]">.</span>
                                    <p className="border border-green-700 rounded-sm text-green-700 font-black px-0.5 text-[9px]">
                                        AI
                                    </p>
                                    <span className="px-0.5 -mt-5 text-3xl text-[#767778]">.</span>
                                    <div className="hidden lg:flex">
                                        <button className="underline text-[12px] text-[#555555] font-medium">
                                            Similar Stories
                                        </button>
                                        <span className="px-1 leading-0.5 -mt-5 text-3xl text-[#767778]">
                                            .
                                        </span>
                                    </div>
                                    <p className="lg:font-medium  whitespace-nowrap">
                                        {articleInfo.publisherName?.slice(0, 15)}
                                    </p>
                                    <span className="px-0.5 -mt-5 text-3xl text-[#767778]">.</span>
                                    <img src={capsule} alt="img-capsule" width={8} />
                                    <span className="px-0.5 -mt-5 text-3xl text-[#767778]">.</span>
                                    <p
                                        className={
                                            randNum < 2.5
                                                ? "px-1 font-semibold bg-[#E74839] rounded-sm text-white text-[8px]"
                                                : "px-1 font-semibold bg-[#269707] rounded-sm text-white text-[8px]"
                                        }
                                    >
                                        {randNum}
                                    </p>
                                </div>
                            </div>
                            <a
                                href={articleInfo.url}
                                rel="noreferrer"
                                target="_blank"
                                className="text-[13px] font-bold lg:hidden overflow-hidden leading-4 h-[32px] line-clamp-2"
                            >
                                {articleInfo.title?.slice(0, 75)}...
                            </a>
                            <a
                                href={articleInfo.url}
                                rel="noreferrer"
                                target="_blank"
                                className="text-base font-bold leading-4 hidden lg:flex mb-1"
                            >
                                {articleInfo.title?.slice(0, 47)}...
                            </a>
                            {/* {articleInfo.description && (
                                <p className="lg:hidden text-[10px] font-light mb-1 flex-nowrap whitespace-pre">
                                    {articleInfo.description?.slice(0, 25)}...
                                </p>
                            )} */}
                            {articleInfo.description && (
                                <p className="hidden lg:flex text-[14px] font-light mb-1">
                                    {articleInfo.description?.slice(0, 110)}...
                                </p>
                            )}

                            <div className="flex lg:hidden relative w-[170px] items-center justify-start mt-1">
                                <button className="underline text-[10px] text-[#0085FF] font-medium">
                                    Similar Stories
                                </button>
                                <span className="px-1.5 leading-0.5 -mt-5 text-3xl text-[#767778]">
                                    .
                                </span>
                                <p className="flex items-center text-[10px] text-[#767778]">
                                    <Eye /> <span className="pl-1"> 24.6K</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center ml-3 mr-4">
                {/* {articleInfo.price && ( */}
                <div className="mr-2 lg:mr-10">
                    <Component
                        idKey={idKey}
                        url={articleInfo.url}
                        price={articleInfo.price}
                        options={options}
                        currency={currency}
                        currencyRate={currencyRate}
                    />

                    <p className="text-[9px] lg:text-[13px] text-[#727272] text-center font-medium">
                        {idKey < 3 && "PayWindow"}
                        {idKey > 2 && <> {idKey % 3 === 0 ? "PayWall" : "Free"} </>}
                    </p>
                </div>
                {/* )} */}
                <DemandSection />
            </div>
        </div>
    );
};

export default SearchItem;
