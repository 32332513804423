const Facebook = () => {
    return (
        <svg
            width="8"
            height="16"
            viewBox="0 0 8 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.54298 3.30499C7.12743 3.22188 6.56621 3.15979 6.21324 3.15979C5.2575 3.15979 5.19541 3.57533 5.19541 4.2402V5.42377H7.58453L7.37628 7.87547H5.19541V15.3328H2.204V7.87547H0.666504V5.42377H2.204V3.90728C2.204 1.83005 3.18029 0.666534 5.63149 0.666534C6.48311 0.666534 7.10642 0.791198 7.91648 0.957415L7.54298 3.30499Z"
                fill="black"
            />
        </svg>
    );
};

export default Facebook;
