import { Suspense, lazy } from "react";
import Graph from "../CandleGraph/index";

const SomeComponent = lazy(() => import("../CandleGraph/index"));

export default (props) => {
    return (
        <>
            {process.env.LAZY === "lazy" ? (
                <Suspense fallback={"loading.."}>
                    <SomeComponent {...props} />
                </Suspense>
            ) : (
                <Graph {...props} />
            )}
        </>
    );
};
// Usage
