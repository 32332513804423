import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { motion, AnimatePresence } from "framer-motion";

import Services from "shared/API/Services";

import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import SearchTypes from "./SearchTypes";
import Loader from "shared/spinner/Loader";
// import Kunatologo from "shared/icons/KunatoLogo";
// import ronaldo from "assests/images/ronaldo.png";
import noResult from "assests/images/no-result.png";

// import Pagination from "./Pagination";

let options = {
    theme: "dark",
    demand: true,
    popup: true,
    currency: "inr",
};

const Search = (props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [articlesData, setData] = useState([]);
    const [articleUrls, setArticleUrls] = useState([]);
    const searchKey = queryString.parse(props.location.search).q;
    const [searchTerm, setSearchTerm] = useState(searchKey || "");
    const [scrollPosition, setPosition] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [noMoreResults, setNoMoreResults] = useState(false);
    const [currency, setCurrency] = useState("inr");

    const listInnerRef = useRef();

    // animation logic
    const containerVariants = {
        hidden: {
            opacity: 0,
            y: -100,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { ease: "linear", delay: 0, duration: 0.4 },
        },
        exit: {
            opacity: 0,
            y: -200,
            transition: { ease: "linear", duration: 0.2 },
        },
    };

    // Function to call the article valuate API
    const getSearch = async () => {
        if (loading || loadingMore || noMoreResults) return;
        if (page < 2) {
            setLoading(true);
        } else {
            setLoadingMore(true);
        }
        let urls;
        const data = await Services.getSearchResults(searchTerm, page);
        if (data.length < 9) {
            setNoMoreResults(true);
        }
        if (data.length) {
            setData((prevItems) => [...prevItems, ...data]);
            // setData(data);
            urls = data?.map((articles) => articles.url);
            setArticleUrls((prevUrls) => [...prevUrls, ...urls]);
            // setArticleUrls(urls);
            // window.scrollTo(0, 0);
        }
        setTimeout(() => {
            setLoading(false);
            setLoadingMore(false);
        }, 500);
    };

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - 100) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    //Function to call API for new search
    const handleSearch = (_page) => {
        setLoading(true);
        setData([]);
        setPage(1);
        props.history.push(`/search?q=${searchTerm.trim()}`);
        getSearch(_page);
    };

    useEffect(() => {
        let containerList = document.getElementsByClassName("searchItemSelector");

        if (containerList) {
            Array.prototype.forEach.call(containerList, function (containerItem) {
                containerItem.scrollLeft = 151; // Scroll the container 200 pixels to the right
            });
        }
    }, [loading, loadingMore]);

    useLayoutEffect(() => {
        function updatePosition() {
            setPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    //function to call infinite scroll function
    useEffect(() => {
        window.addEventListener("touchmove", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <div
            ref={listInnerRef}
            className="flex flex-1 w-full flex-col min-h-full bg-white text-black"
        >
            <SearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSubmit={(_page) => handleSearch(_page)}
                scrollPosition={scrollPosition}
            />
            <AnimatePresence>
                {scrollPosition > 250 && (
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="flex w-full sticky top-0 z-50"
                    >
                        <div className="w-full absolute">
                            <SearchBar
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                handleSubmit={(_page) => handleSearch(_page)}
                                scrollPosition={scrollPosition}
                            />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <SearchTypes
                scrollPosition={scrollPosition}
                setCurrency={setCurrency}
                currency={currency}
            />

            {loading ? (
                <div className="flex flex-1 items-center justify-center h-full">
                    <Loader />
                </div>
            ) : (
                <>
                    {articlesData?.length > 0 ? (
                        <>
                            <SearchResults
                                articlesData={articlesData}
                                options={options}
                                articleUrls={articleUrls}
                                currency={currency}
                            />
                            {loadingMore && (
                                <div className="w-full flex items-center justify-center my-4 md:max-w-2xl lg:ml-[220px]">
                                    <Loader />
                                </div>
                            )}
                            {/* <Pagination page={page} setPage={setPage} /> */}
                        </>
                    ) : (
                        <div className="flex flex-col text-center w-full items-center justify-center h-[80vh]">
                            <img src={noResult} alt="img-no-result" width={96} className="mb-10" />
                            <h4 className="text-blue1 font-bold mb-2.5">Result Not Found</h4>
                            <p className="text-[13px] text-gray-400 w-[229px]">
                                Whoops....this information is not available at this moment{" "}
                            </p>
                        </div>
                    )}
                </>
            )}

            {/* {!noMoreResults && (
                <>
                    {loadingMore ? (
                        <div className="flex flex-1 items-center justify-center h-full my-4">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {articlesData.length > 14 && (
                                <div className="flex justify-center items-center text-white text-sm my-3 ">
                                    <button
                                        onClick={handleMore}
                                        className="bg-theme-backdrop p-2 rounded-md bg-blue-500 w-32 font-medium"
                                    >
                                        Load More
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </>
            )} */}
        </div>
    );
};

export default withRouter(Search);
