import React from "react";

import Component from "../../Popup";
import ChevronDown2 from "shared/icons/ChevronDown2";

const Filter = ({ articleInfo, setModalFilter, modalFilter }) => {
    const setClass = (filter) => {
        let _class = "cursor-pointer mr-2.5 text-[11px] font-medium";

        if (modalFilter === filter) {
            _class =
                "cursor-pointer mr-2.5 text-[11px] font-medium bg-[#0085FF] px-0.5 rounded-sm text-white";
        }

        return _class;
    };

    return (
        <div className="px-3 mb-5">
            <ul className="flex items-center list-none mb-5">
                <li className="text-xs font-medium mr-5 text-[#0085FF] border-b-2 border-[#0085FF]">
                    Performance
                </li>
                <li className="text-xs font-medium text-[#989898]">Content Genome</li>
            </ul>

            <div className="flex items-center justify-between mb-3">
                <ul className="flex items-center list-none">
                    <li
                        onClick={() => {
                            setModalFilter("6h");
                        }}
                        className={setClass("6h")}
                    >
                        6H
                    </li>
                    <li
                        onClick={() => {
                            setModalFilter("1d");
                        }}
                        className={setClass("1d")}
                    >
                        1D
                    </li>
                    <li
                        onClick={() => {
                            setModalFilter("30d");
                        }}
                        className={setClass("30d")}
                    >
                        1M
                    </li>
                    <li
                        onClick={() => {
                            setModalFilter("lt");
                        }}
                        className={setClass("lt")}
                    >
                        LT
                    </li>
                </ul>

                <div className="flex flex-col items-center">
                    <Component
                        // idKey={idKey}
                        url={articleInfo.url}
                        price={articleInfo.price}
                        // options={options}
                        currency={"inr"}
                        currencyRate={"1"}
                    />
                    <span className="text-[7px] font-medium">PayWindow</span>
                </div>
            </div>

            <div className="flex items-center justify-between">
                <p className="text-[9px] font-medium text-[#8B8B8B]">
                    Max: ₹812.65 <span className="px-1">Min: ₹712.23</span> Avg: ₹746.64
                </p>

                <div className="text-[10px] font-medium mr-2.5 flex items-center">
                    <span className="mr-1">₹ INR</span> <ChevronDown2 />
                </div>
            </div>
        </div>
    );
};

export default Filter;
