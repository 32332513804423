// import { useHistory } from "react-router-dom";

import Search from "./Search";
import Kunatologo from "shared/icons/KunatoLogo";
import Footer from "shared/Footer";

import Logo from "assests/images/Logo.png";

/**
 *  Home page
 */
const Home = () => {
    // const history = useHistory();

    // const handleSubmit = (searchTerm) => {
    //     history.push(`/search?q=${searchTerm}`);
    //     window.location.reload();
    // };

    return (
        <div className="flex flex-1 w-full min-h-full flex-col text-black bg-white">
            <div className="flex flex-col flex-1 w-full">
                <div className="flex flex-col flex-1 justify-center items-center my-20 lg:-mt-20">
                    <div className="flex">
                        <img
                            src={Logo}
                            alt="logo-img"
                            className="mx-2 w-[31px] h-[33px] lg:w-10 lg:h-10"
                        />
                        <Kunatologo />
                    </div>
                    <Search />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Home;
