const Eye = () => {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_385_13253)">
                <path
                    d="M0.375 4.5C0.375 4.5 1.875 1.5 4.5 1.5C7.125 1.5 8.625 4.5 8.625 4.5C8.625 4.5 7.125 7.5 4.5 7.5C1.875 7.5 0.375 4.5 0.375 4.5Z"
                    stroke="#6D6D6D"
                    strokeOpacity="0.87"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 5.625C5.12132 5.625 5.625 5.12132 5.625 4.5C5.625 3.87868 5.12132 3.375 4.5 3.375C3.87868 3.375 3.375 3.87868 3.375 4.5C3.375 5.12132 3.87868 5.625 4.5 5.625Z"
                    stroke="#6D6D6D"
                    strokeOpacity="0.87"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_385_13253">
                    <rect width="9" height="9" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Eye;
