import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";

const DonutRatings = () => {
    const [options, setOptions] = useState("");

    useEffect(() => {
        setOptions({
            tooltip: {
                show: false,
            },
            grid: {
                left: "0%",
                right: "0%",
                bottom: "0%",
                top: "0%",
                containLabel: true,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: "Ratings",
                    type: "pie",
                    startAngle: -90, // Set the desired start angle here
                    radius: ["80%", "95%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: "center",
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 20,
                            fontWeight: "bold",
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: [
                        {
                            value: 65,
                            itemStyle: {
                                color: "#BBDA3F", // Set the desired color for the donut slice
                            },
                        },
                        {
                            value: 100 - 65, // Calculate the remaining percentage
                            itemStyle: {
                                color: "#F1FAFF", // Set the remaining slice color
                            },
                        },
                    ],
                    // data: graphData.map((points) => points.value),
                    // itemStyle: {
                    //     borderRadius: 1,
                    //     borderColor: "#aaa",
                    //     borderWidth: 1,
                    //     color: "#5AC0F4",
                    // },
                },
            ],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!options) return <div> Loading....</div>;

    return (
        <div className="border relative px-[26px] py-2 rounded flex flex-col items-center text-center">
            <div>
                <h6 className="text-xs mb-1.5">Ratings</h6>
            </div>
            <p className="text-base font-bold absolute bottom-[22px] text-[#87A900]">3.2</p>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "50px",
                        height: "50px",
                    }}
                />
            )}
        </div>
    );
};

export default DonutRatings;
