import DemandSection from "components/Search/Popup/DemandSection";
import React from "react";
import DonutRatings from "./DonutRatings";

const LastSection = () => {
    return (
        <div className="flex items-center justify-between mb-2.5 px-3">
            <div className="border rounded flex flex-col items-center py-2 px-6">
                <h6 className="text-xs mb-1">Demand</h6>
                <DemandSection sizeDown={true} />
            </div>

            <DonutRatings />

            <div className="border rounded flex flex-col items-center py-2 px-6">
                <h6 className="text-xs mb-5">Reads</h6>

                <h3 className="text-lg font-medium text-[#797979] mb-2">21.4K</h3>
            </div>
        </div>
    );
};

export default LastSection;
