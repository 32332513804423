import { useEffect, useState } from "react";
import SvgIcons from "./svgIcons";

import "./popupStyle.css";
import { converterCurrency } from "shared/convertCurrency";
import { currencyDisplay } from "shared/currencyDisplay";
// import Modal from "./Modal";

const Component = ({ url, price, options, currencyRate, currency, idKey }) => {
    const [articlePrice, setArticlePrice] = useState("");
    const [priceDirection, setPriceDirection] = useState(Math.random() < 0.3 ? "down" : "up");
    const [modal, setModal] = useState(false);
    const [animationDirection, setAnimationDirection] = useState(
        Math.random() < 0.3 ? "down" : "up"
    );

    const setClass = () => {
        let _class;
        if (idKey && idKey > 2) {
            if (idKey % 3 !== 0) {
                _class = "px-1 relative  text-[#ADA7A7] text-center rounded ";
            } else {
                _class = "px-1 relative text-center rounded ";
            }
        } else {
            _class = "px-1 relative  text-center rounded ";
        }

        if (animationDirection === "up") {
            _class = _class + "qx-widget-priceUp";
        }
        if (animationDirection === "down") {
            _class = _class + "qx-widget-priceDown";
        }
        return _class;
    };

    useEffect(() => {
        if (!articlePrice) {
            setArticlePrice(price);
        }

        let time = Math.floor(Math.random() * (1500 - 500 + 1) + 500);

        if (articlePrice > price) {
            setTimeout(() => {
                setArticlePrice(price);
                setPriceDirection("down");
                setAnimationDirection("down");
            }, time);
        } else {
            setTimeout(() => {
                setArticlePrice(price);
                setPriceDirection("up");
                setAnimationDirection("up");
            }, time);
        }

        setTimeout(() => {
            setAnimationDirection("");
        }, 3000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price]);

    // let _articlePrice = `${articlePrice}`.slice(0, `${articlePrice}`.indexOf(".") + 3);

    return (
        <div
            className={setClass()}
            onMouseEnter={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
        >
            <h5
                onClick={() => setModal(!modal)}
                className="flex items-center py-0.5 justify-center text-[15px] lg:text-base font-semibold min-w-[75px] cursor-pointer"
            >
                {articlePrice && (
                    <>
                        {currencyDisplay(currency)}
                        {converterCurrency(articlePrice, currencyRate)}{" "}
                    </>
                )}
                <SvgIcons priceDirection={priceDirection} />
            </h5>
            {/* {modal && (
                <Modal
                    url={url}
                    articlePrice={price}
                    options={options}
                    priceDirection={priceDirection}
                />
            )} */}
        </div>
    );
};

export default Component;
