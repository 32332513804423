import { Switch, Route } from "react-router-dom";

// components
import HomePage from "components/Home/index";
import Search from "components/Search/index";

let Routes = () => {
    return (
        <Switch>
            <Route path="/" exact={true} component={HomePage} />
            <Route path="/search" exact={true} component={Search} />
        </Switch>
    );
};

export default Routes;
