import { useState } from "react";
import { useHistory } from "react-router-dom";
import Close from "shared/icons/Close";
import SearchLogo from "shared/icons/SearchLogo";

import "./style.scss";

/**
 * Search bar page
 */

const Search = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchTerm.trim().length > 1) {
            history.push(`/search?q=${searchTerm.trim()}`);
            window.location.reload();
        }
    };

    return (
        <div className="mt-10 flex flex-col w-full justify-center items-center max-w-lg px-2 md:px-0">
            <form className="w-full flex" onSubmit={(e) => handleSubmit(e)}>
                <div className="flex items-center w-full rounded-xl bg-[#F2F2F2]">
                    <div className="relative w-full">
                        <input
                            type="search"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            value={searchTerm}
                            autoFocus
                            placeholder="Search here"
                            className="py-3 pl-4 pr-6 rounded-3xl w-full border-none focus:outline-none text-black bg-transparent text-sm"
                        />
                        {searchTerm.length > 0 && (
                            <span
                                className="absolute top-3 right-0 cursor-pointer"
                                onClick={() => setSearchTerm("")}
                            >
                                <Close />
                            </span>
                        )}
                    </div>

                    <button
                        onClick={() => handleSubmit()}
                        className="hover:bg-blue1 py-3 pl-2.5 px-3  rounded-r-3xl button-hover"
                    >
                        <SearchLogo color={"#888888"} />
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Search;
