import { useEffect, useState } from "react";

import Services from "shared/API/Services";

import SearchItem from "../SearchItem";
import ScrollSearchItem from "../ScrollSearchItem.js";
import QRCode from "../Qrcode";

const SearchResults = ({ articlesData, options, articleUrls, currency }) => {
  const [data, setData] = useState(articlesData);
  const [loading, setLoading] = useState(false);
  const [currencyRate, setCurrencyRate] = useState("");

  // Function to get prcies of articles
  const getPrices = async () => {
    setLoading(true);
    const pricesData = await Services.getPrices(options.currency, articleUrls);
    let _articlesData = articlesData;
    _articlesData.forEach((article) => {
      if (pricesData[article.url]) {
        article.price = pricesData[article.url];
      }
    });
    setData(_articlesData);
    setLoading(false);
  };

  // Function to convert currency
  const currencyConverter = async () => {
    const response = await fetch(
      `https://a2.qx.live/currency-conversion?base=${currency}&to=inr`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    // Storing data in form of JSON
    const data = await response.json();
    if (data.ok) {
      setCurrencyRate(data.rate);
    }
  };

  useEffect(() => {
    setData(articlesData);
    const intervalId = setInterval(() => {
      getPrices();
    }, 5000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, articlesData]);

  useEffect(() => {
    currencyConverter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <>
      {data.map((article, index) => (
        <div
          style={
            index < 3
              ? {
                  background:
                    "linear-gradient(180deg, rgba(253, 215, 141, 0.14) 60.94%, rgba(255, 225, 167, 0) 100%)",
                  borderBottom: "2px solid #fff",
                }
              : { borderBottom: "2px solid #F2F2F2" }
          }
          className="flex overflow-x-scroll overflow-y-hidden min-h-28 h-28 snap-mandatory snap-x lg:w-[810px] items-center lg:justify-center searchItemSelector lg:ml-[90px]"
        >
          <ScrollSearchItem
            idKey={index}
            articleInfo={article}
            options={options}
            currency={currency}
            currencyRate={currencyRate}
          />
          <SearchItem
            idKey={index}
            articleInfo={article}
            options={options}
            currency={currency}
            currencyRate={currencyRate}
          />
          <QRCode />
        </div>
      ))}
    </>
  );
};

export default SearchResults;
