// import { h } from "preact";

const SvgIcons = ({ priceDirection }) => {
    return (
        <>
            {priceDirection === "up" && (
                <svg
                    width="6"
                    height="5"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px", marginTop: "4px" }}
                >
                    <path d="M4.5 0L8.39711 7.5H0.602886L4.5 0Z" fill="#24A900" />
                </svg>
            )}
            {priceDirection === "down" && (
                <svg
                    width="6"
                    height="5"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "2px", marginTop: "4px" }}
                >
                    <path d="M4.5 8L8.39711 0.5H0.602886L4.5 8Z" fill="#E74839" />
                </svg>
            )}
        </>
    );
};

export default SvgIcons;
