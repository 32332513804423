import { useState } from "react";

import code from "assests/images/code.png";
import QRModal from "./QRModal";

const QRCode = () => {
  const [modal, setModal] = useState(false);
  return (
    <div
      onClick={() => setModal(!modal)}
      className="flex lg:hidden flex-col items-center justify-center bg-white min-w-[150px] cursor-pointer h-[100px]"
    >
      <h6 className="text-[10px] font-semibold mb-2">Scan QR Code</h6>
      <img src={code} alt="img-qrcode" width={47} height={47} />
      {modal && <QRModal closeModal={() => setModal(false)} />}
    </div>
  );
};

export default QRCode;
