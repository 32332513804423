// import DemandSection from "../Popup/DemandSection";
import { useState } from "react";
import Modal from "../Popup/Modal";
import ArticleModal from "../SearchResults/ArticleModal";

const ScrollSearchItem = ({ idKey, articleInfo, options, currencyRate, currency }) => {
    const [modal, setModal] = useState(false);

    return (
        <div
            key={idKey}
            className="flex lg:hidden items-center justify-between bg-white w-[44vw] h-28 lg:min-w-[721px] pt-4 md:max-w-2xl lg:ml-[140px]"
        >
            <div className="flex items-center">
                <div className="flex flex-col">
                    <div className="flex items-center lg:justify-between max-w-md">
                        <div className="mx-1 mr-0">
                            <Modal
                                url={articleInfo.url}
                                articlePrice={articleInfo.price}
                                options={options}
                                priceDirection={"up"}
                                currency={currency}
                                currencyRate={currencyRate}
                                openGraphModal={() => setModal(!modal)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {modal && (
                <ArticleModal
                    closeModal={() => setModal(false)}
                    articleInfo={articleInfo}
                    options={options}
                    currency={currency}
                    currencyRate={currencyRate}
                />
            )}
        </div>
    );
};

export default ScrollSearchItem;
