// Function to return currency logo when its selected
export const currencyDisplay = (currency) => {
    let c = "$";
    if (currency === "inr") {
        c = "₹";
    } else if (currency === "eur") {
        c = "€";
    } else if (currency === "gbp") {
        c = "£";
    } else if (currency === "jpy") {
        c = "¥";
    } else if (currency === "cad") {
        c = "$";
    } else if (currency === "aud") {
        c = "$";
    } else if (currency === "aed") {
        c = "د.إ";
    } else if (currency === "ils") {
        c = "₪";
    } else if (currency === "cny") {
        c = "¥";
    } else if (currency === "brl") {
        c = "R$";
    }

    return c;
};
