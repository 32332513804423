import { searchAPI, articlePriceAPI } from "shared/utils";

class APIService {
    // Function to valuate the article
    getSearchResults = async (searchTerm, page) => {
        // Storing response
        const response = await fetch(searchAPI, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: searchTerm, page: page }),
        });
        // Storing data in form of JSON
        const data = await response.json();

        return data;
    };

    // Function to get prices of the urls
    getPrices = async (currency, urls) => {
        // const prices = {};

        const response = await fetch(articlePriceAPI, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                currency: currency,
                urls: urls,
            }),
        });

        // Storing data in form of JSON
        let data = await response.json();

        // // // return data;
        // urls.forEach((url) => {
        //     prices[url] = data[url];
        // });

        return data;
    };
}

const Services = new APIService();

export default Services;
