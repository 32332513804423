const Kunatologo = ({ width, height, textSize, bottom }) => {
    let _width = "140";
    let _height = "45";

    if (width) _width = width;
    if (height) _height = height;
    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 189 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M57.4326 10.5918V25.2708C57.4326 29.6295 53.873 33.1364 49.5614 33.1364C45.1997 33.1364 41.6902 29.6295 41.6902 25.2708V10.5918H34.8718V25.2708C34.8718 33.3869 41.4395 39.9499 49.5614 39.9499C57.6332 39.9499 64.251 33.3869 64.251 25.2708V10.5918H57.4326Z"
                fill="#0085FF"
            />
            <path
                d="M84.9094 10.5918C76.7875 10.5918 70.2198 17.2049 70.2198 25.2708V39.9499H77.0382V25.2708C77.0382 20.9623 80.5476 17.4053 84.9094 17.4053C89.221 17.4053 92.7806 20.9623 92.7806 25.2708V39.9499H99.599V25.2708C99.599 17.2049 92.9811 10.5918 84.9094 10.5918Z"
                fill="#0085FF"
            />
            <path
                d="M119.557 10.5918C111.435 10.5918 104.867 17.2049 104.867 25.2708C104.867 33.3869 111.435 39.9499 119.557 39.9499H120.459V33.1364H119.557C115.195 33.1364 111.685 29.6295 111.685 25.2708C111.685 20.9623 115.195 17.4053 119.557 17.4053H127.428V39.9499H134.246V10.5918H119.557Z"
                fill="#0085FF"
            />
            <path
                d="M148.488 10.5918V3.07692H141.67V39.9499H148.488V17.4053H156.009V10.5918H148.488Z"
                fill="#0085FF"
            />
            <path
                d="M173.853 10.5918C165.731 10.5918 159.164 17.2049 159.164 25.2708C159.164 33.3869 165.731 39.9499 173.853 39.9499C181.925 39.9499 188.543 33.3869 188.543 25.2708C188.543 17.2049 181.925 10.5918 173.853 10.5918ZM173.853 33.1364C169.492 33.1364 165.982 29.6295 165.982 25.2708C165.982 20.9623 169.492 17.4053 173.853 17.4053C178.165 17.4053 181.725 20.9623 181.725 25.2708C181.725 29.6295 178.165 33.1364 173.853 33.1364Z"
                fill="#0085FF"
            />
            <rect width="7.65957" height="40" fill="#0085FF" />
            <path
                d="M24.0426 0.425552H31.0638V3.82981L19.5745 18.7234C19.5745 18.7234 18.6773 19.5445 18.7234 20.2128C18.7646 20.8105 19.5745 21.4894 19.5745 21.4894L31.0638 36.383V39.7873H24.0426L12.1277 23.8298C12.1277 23.8298 11.0638 21.6851 11.0638 20.2128C11.0638 18.7404 12.1277 16.5958 12.1277 16.5958L24.0426 0.425552Z"
                fill="#0085FF"
            />
            <path
                d="M82.4502 46L84.9251 53.5361H85.025L87.4999 46H88.9501L85.7501 55.253H84.2L81 46H82.4502Z"
                fill="#838383"
            />
            <path
                d="M91.4141 55.4066C90.9915 55.4066 90.6094 55.3253 90.2678 55.1627C89.9263 54.997 89.6556 54.7575 89.4559 54.4443C89.2591 54.131 89.1607 53.747 89.1607 53.2922C89.1607 52.9006 89.233 52.5783 89.3777 52.3253C89.5225 52.0723 89.7179 51.872 89.9639 51.7244C90.21 51.5768 90.4849 51.4654 90.7889 51.3901C91.0928 51.3148 91.4025 51.2575 91.718 51.2184C92.1175 51.1702 92.4417 51.131 92.6906 51.1009C92.9396 51.0678 93.1205 51.0151 93.2334 50.9428C93.3463 50.8705 93.4027 50.753 93.4027 50.5904V50.5587C93.4027 50.1642 93.2956 49.8584 93.0814 49.6416C92.8701 49.4247 92.5546 49.3163 92.1349 49.3163C91.6978 49.3163 91.3533 49.4172 91.1015 49.619C90.8526 49.8178 90.6803 50.0392 90.5848 50.2831L89.3647 49.994C89.5095 49.5723 89.7208 49.2319 89.9986 48.9729C90.2794 48.7108 90.6022 48.5211 90.9669 48.4036C91.3316 48.2831 91.7152 48.2229 92.1175 48.2229C92.3838 48.2229 92.666 48.256 92.9642 48.3223C93.2652 48.3855 93.546 48.503 93.8065 48.6747C94.0699 48.8464 94.2856 49.0919 94.4535 49.4111C94.6213 49.7274 94.7053 50.1386 94.7053 50.6446V55.253H93.4374V54.3042H93.3853C93.3014 54.4789 93.1755 54.6506 93.0076 54.8193C92.8397 54.988 92.6241 55.128 92.3607 55.2395C92.0972 55.3509 91.7817 55.4066 91.4141 55.4066ZM91.6963 54.3223C92.0553 54.3223 92.3621 54.2485 92.6168 54.1009C92.8744 53.9533 93.0698 53.7605 93.203 53.5226C93.339 53.2816 93.4071 53.0241 93.4071 52.75V51.8554C93.3607 51.9036 93.271 51.9488 93.1379 51.991C93.0076 52.0301 92.8585 52.0648 92.6906 52.0949C92.5227 52.122 92.3592 52.1476 92.2 52.1717C92.0408 52.1928 91.9076 52.2108 91.8005 52.2259C91.5487 52.259 91.3186 52.3148 91.1102 52.3931C90.9047 52.4714 90.7397 52.5843 90.6152 52.7319C90.4936 52.8765 90.4328 53.0693 90.4328 53.3102C90.4328 53.6446 90.5515 53.8976 90.7889 54.0693C91.0262 54.238 91.3287 54.3223 91.6963 54.3223Z"
                fill="#838383"
            />
            <path d="M97.7327 46V55.253H96.4345V46H97.7327Z" fill="#838383" />
            <path
                d="M103.704 52.375V48.3133H105.007V55.253H103.73V54.0512H103.661C103.507 54.4217 103.261 54.7304 102.922 54.9774C102.587 55.2214 102.168 55.3434 101.668 55.3434C101.239 55.3434 100.86 55.2455 100.53 55.0497C100.203 54.8509 99.9453 54.5572 99.7571 54.1687C99.5719 53.7801 99.4792 53.2997 99.4792 52.7274V48.3133H100.777V52.5648C100.777 53.0377 100.903 53.4142 101.155 53.6943C101.407 53.9744 101.734 54.1145 102.136 54.1145C102.38 54.1145 102.621 54.0512 102.862 53.9247C103.105 53.7982 103.306 53.6069 103.465 53.3509C103.627 53.0949 103.707 52.7696 103.704 52.375Z"
                fill="#838383"
            />
            <path
                d="M109.625 55.3931C108.968 55.3931 108.402 55.247 107.928 54.9548C107.456 54.6596 107.091 54.2455 106.833 53.7123C106.579 53.1762 106.451 52.5482 106.451 51.8283C106.451 51.1175 106.579 50.491 106.833 49.9488C107.091 49.4066 107.45 48.9834 107.91 48.6792C108.373 48.375 108.915 48.2229 109.534 48.2229C109.91 48.2229 110.275 48.2877 110.628 48.4172C110.981 48.5467 111.298 48.75 111.579 49.0271C111.86 49.3042 112.081 49.6642 112.243 50.1069C112.406 50.5467 112.487 51.0813 112.487 51.7108V52.1898H107.185V51.1777H111.214C111.214 50.8223 111.145 50.5075 111.006 50.2334C110.867 49.9563 110.672 49.738 110.42 49.5783C110.171 49.4187 109.879 49.3389 109.543 49.3389C109.178 49.3389 108.86 49.4322 108.588 49.619C108.318 49.8027 108.11 50.0437 107.962 50.3419C107.818 50.637 107.745 50.9578 107.745 51.3042V52.0949C107.745 52.5587 107.823 52.9533 107.98 53.2786C108.139 53.6039 108.36 53.8524 108.644 54.0241C108.928 54.1928 109.259 54.2771 109.638 54.2771C109.884 54.2771 110.109 54.241 110.311 54.1687C110.514 54.0934 110.689 53.9819 110.837 53.8343C110.984 53.6867 111.097 53.5045 111.175 53.2877L112.404 53.5181C112.306 53.8946 112.129 54.2244 111.874 54.5075C111.623 54.7877 111.306 55.006 110.923 55.1627C110.544 55.3163 110.112 55.3931 109.625 55.3931Z"
                fill="#838383"
            />
            <path
                d="M117.267 55.253V46H118.609V50.4187H118.717L122.447 46H124.136L120.55 50.1657L124.149 55.253H122.534L119.659 51.119L118.609 52.375V55.253H117.267Z"
                fill="#838383"
            />
            <path
                d="M126.58 51.1325V55.253H125.282V48.3133H126.528V49.4428H126.611C126.764 49.0753 127.005 48.7801 127.332 48.5572C127.662 48.3343 128.077 48.2229 128.578 48.2229C129.032 48.2229 129.43 48.3223 129.772 48.5211C130.113 48.7169 130.378 49.009 130.566 49.3976C130.754 49.7861 130.849 50.2666 130.849 50.8389V55.253H129.55V51.0015C129.55 50.4985 129.424 50.1054 129.173 49.8223C128.921 49.5361 128.575 49.3931 128.135 49.3931C127.834 49.3931 127.566 49.4608 127.332 49.5964C127.1 49.7319 126.916 49.9307 126.78 50.1928C126.647 50.4518 126.58 50.7651 126.58 51.1325Z"
                fill="#838383"
            />
            <path
                d="M135.399 55.3931C134.774 55.3931 134.228 55.244 133.762 54.9458C133.296 54.6476 132.934 54.2304 132.677 53.6943C132.419 53.1581 132.29 52.5316 132.29 51.8148C132.29 51.0949 132.419 50.4654 132.677 49.9262C132.934 49.387 133.296 48.9684 133.762 48.6702C134.228 48.372 134.774 48.2229 135.399 48.2229C136.024 48.2229 136.57 48.372 137.036 48.6702C137.502 48.9684 137.864 49.387 138.121 49.9262C138.379 50.4654 138.508 51.0949 138.508 51.8148C138.508 52.5316 138.379 53.1581 138.121 53.6943C137.864 54.2304 137.502 54.6476 137.036 54.9458C136.57 55.244 136.024 55.3931 135.399 55.3931ZM135.403 54.259C135.809 54.259 136.144 54.1476 136.411 53.9247C136.677 53.7018 136.874 53.4051 137.001 53.0346C137.131 52.6642 137.196 52.256 137.196 51.8102C137.196 51.3675 137.131 50.9608 137.001 50.5904C136.874 50.2169 136.677 49.9172 136.411 49.6913C136.144 49.4654 135.809 49.3524 135.403 49.3524C134.995 49.3524 134.656 49.4654 134.387 49.6913C134.121 49.9172 133.923 50.2169 133.792 50.5904C133.665 50.9608 133.601 51.3675 133.601 51.8102C133.601 52.256 133.665 52.6642 133.792 53.0346C133.923 53.4051 134.121 53.7018 134.387 53.9247C134.656 54.1476 134.995 54.259 135.403 54.259Z"
                fill="#838383"
            />
            <path
                d="M141.2 55.253L139.237 48.3133H140.579L141.886 53.4096H141.951L143.262 48.3133H144.604L145.906 53.387H145.972L147.27 48.3133H148.611L146.653 55.253H145.329L143.974 50.2425H143.874L142.52 55.253H141.2Z"
                fill="#838383"
            />
            <path d="M151.142 46V55.253H149.843V46H151.142Z" fill="#838383" />
            <path
                d="M155.763 55.3931C155.105 55.3931 154.54 55.247 154.065 54.9548C153.593 54.6596 153.228 54.2455 152.971 53.7123C152.716 53.1762 152.589 52.5482 152.589 51.8283C152.589 51.1175 152.716 50.491 152.971 49.9488C153.228 49.4066 153.587 48.9834 154.047 48.6792C154.511 48.375 155.052 48.2229 155.671 48.2229C156.048 48.2229 156.412 48.2877 156.766 48.4172C157.119 48.5467 157.436 48.75 157.716 49.0271C157.997 49.3042 158.219 49.6642 158.381 50.1069C158.543 50.5467 158.624 51.0813 158.624 51.7108V52.1898H153.322V51.1777H157.352C157.352 50.8223 157.282 50.5075 157.143 50.2334C157.004 49.9563 156.809 49.738 156.557 49.5783C156.308 49.4187 156.016 49.3389 155.68 49.3389C155.315 49.3389 154.997 49.4322 154.725 49.619C154.456 49.8027 154.247 50.0437 154.1 50.3419C153.955 50.637 153.882 50.9578 153.882 51.3042V52.0949C153.882 52.5587 153.961 52.9533 154.117 53.2786C154.276 53.6039 154.498 53.8524 154.781 54.0241C155.065 54.1928 155.396 54.2771 155.776 54.2771C156.022 54.2771 156.246 54.241 156.449 54.1687C156.651 54.0934 156.826 53.9819 156.974 53.8343C157.122 53.6867 157.234 53.5045 157.313 53.2877L158.541 53.5181C158.443 53.8946 158.266 54.2244 158.012 54.5075C157.76 54.7877 157.443 55.006 157.061 55.1627C156.682 55.3163 156.249 55.3931 155.763 55.3931Z"
                fill="#838383"
            />
            <path
                d="M162.561 55.3886C162.023 55.3886 161.542 55.2455 161.119 54.9593C160.7 54.6702 160.37 54.259 160.129 53.7259C159.892 53.1898 159.773 52.5467 159.773 51.7967C159.773 51.0467 159.894 50.4051 160.134 49.872C160.377 49.3389 160.71 48.9307 161.132 48.6476C161.555 48.3645 162.034 48.2229 162.57 48.2229C162.984 48.2229 163.316 48.2952 163.568 48.4398C163.823 48.5813 164.02 48.747 164.159 48.9367C164.301 49.1265 164.411 49.2937 164.489 49.4383H164.567V46H165.865V55.253H164.597V54.1732H164.489C164.411 54.3208 164.298 54.4895 164.15 54.6792C164.005 54.869 163.806 55.0346 163.551 55.1762C163.296 55.3178 162.966 55.3886 162.561 55.3886ZM162.847 54.2364C163.221 54.2364 163.536 54.134 163.794 53.9292C164.055 53.7214 164.251 53.4337 164.385 53.0663C164.521 52.6988 164.589 52.2711 164.589 51.7831C164.589 51.3012 164.522 50.8795 164.389 50.5181C164.256 50.1566 164.06 49.875 163.803 49.6732C163.545 49.4714 163.227 49.3705 162.847 49.3705C162.457 49.3705 162.131 49.4759 161.871 49.6867C161.61 49.8976 161.413 50.1852 161.28 50.5497C161.15 50.9142 161.085 51.3253 161.085 51.7831C161.085 52.247 161.151 52.6642 161.284 53.0346C161.418 53.4051 161.614 53.6988 161.875 53.9157C162.138 54.1295 162.463 54.2364 162.847 54.2364Z"
                fill="#838383"
            />
            <path
                d="M170.514 58C169.985 58 169.529 57.9277 169.147 57.7831C168.767 57.6386 168.458 57.4473 168.217 57.2093C167.977 56.9714 167.798 56.7108 167.679 56.4277L168.795 55.9488C168.873 56.0813 168.977 56.2214 169.107 56.369C169.241 56.5196 169.42 56.6476 169.646 56.753C169.875 56.8584 170.168 56.9111 170.527 56.9111C171.019 56.9111 171.426 56.7861 171.747 56.5361C172.069 56.2892 172.229 55.8946 172.229 55.3524V53.988H172.147C172.069 54.1355 171.956 54.2997 171.808 54.4804C171.663 54.6611 171.464 54.8178 171.209 54.9503C170.954 55.0828 170.623 55.1491 170.215 55.1491C169.688 55.1491 169.213 55.0211 168.791 54.7651C168.371 54.506 168.038 54.125 167.792 53.622C167.549 53.116 167.427 52.494 167.427 51.756C167.427 51.0181 167.547 50.3855 167.788 49.8584C168.031 49.3313 168.364 48.9277 168.786 48.6476C169.209 48.3645 169.688 48.2229 170.223 48.2229C170.637 48.2229 170.972 48.2952 171.226 48.4398C171.481 48.5813 171.679 48.747 171.821 48.9367C171.966 49.1265 172.077 49.2937 172.155 49.4383H172.251V48.3133H173.523V55.4066C173.523 56.003 173.39 56.4925 173.124 56.875C172.857 57.2575 172.497 57.5407 172.043 57.7244C171.591 57.9081 171.082 58 170.514 58ZM170.501 54.0286C170.875 54.0286 171.19 53.9383 171.448 53.7575C171.708 53.5738 171.905 53.3117 172.038 52.9714C172.174 52.628 172.242 52.2169 172.242 51.738C172.242 51.2711 172.176 50.8599 172.043 50.5045C171.909 50.1491 171.714 49.872 171.456 49.6732C171.199 49.4714 170.88 49.3705 170.501 49.3705C170.11 49.3705 169.785 49.4759 169.524 49.6867C169.264 49.8946 169.067 50.1777 168.934 50.5361C168.804 50.8946 168.738 51.2952 168.738 51.738C168.738 52.1928 168.805 52.5919 168.938 52.9352C169.071 53.2786 169.268 53.5467 169.529 53.7395C169.792 53.9322 170.116 54.0286 170.501 54.0286Z"
                fill="#838383"
            />
            <path
                d="M178.139 55.3931C177.482 55.3931 176.916 55.247 176.441 54.9548C175.969 54.6596 175.604 54.2455 175.347 53.7123C175.092 53.1762 174.965 52.5482 174.965 51.8283C174.965 51.1175 175.092 50.491 175.347 49.9488C175.604 49.4066 175.963 48.9834 176.424 48.6792C176.887 48.375 177.428 48.2229 178.047 48.2229C178.424 48.2229 178.789 48.2877 179.142 48.4172C179.495 48.5467 179.812 48.75 180.093 49.0271C180.373 49.3042 180.595 49.6642 180.757 50.1069C180.919 50.5467 181 51.0813 181 51.7108V52.1898H175.699V51.1777H179.728C179.728 50.8223 179.658 50.5075 179.519 50.2334C179.38 49.9563 179.185 49.738 178.933 49.5783C178.684 49.4187 178.392 49.3389 178.056 49.3389C177.691 49.3389 177.373 49.4322 177.101 49.619C176.832 49.8027 176.623 50.0437 176.476 50.3419C176.331 50.637 176.259 50.9578 176.259 51.3042V52.0949C176.259 52.5587 176.337 52.9533 176.493 53.2786C176.652 53.6039 176.874 53.8524 177.157 54.0241C177.441 54.1928 177.772 54.2771 178.152 54.2771C178.398 54.2771 178.622 54.241 178.825 54.1687C179.027 54.0934 179.202 53.9819 179.35 53.8343C179.498 53.6867 179.611 53.5045 179.689 53.2877L180.917 53.5181C180.819 53.8946 180.643 54.2244 180.388 54.5075C180.136 54.7877 179.819 55.006 179.437 55.1627C179.058 55.3163 178.625 55.3931 178.139 55.3931Z"
                fill="#838383"
            />
            <path
                d="M179 42.6494V42H182.16V42.6494H180.886V47H180.272V42.6494H179Z"
                fill="#838383"
            />
            <path
                d="M182.858 42H183.605L184.905 45.8867H184.953L186.252 42H187V47H186.414V43.3818H186.376L185.172 46.9927H184.686L183.482 43.3794H183.444V47H182.858V42Z"
                fill="#838383"
            />
        </svg>
    );
};

export default Kunatologo;
