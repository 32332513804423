import React, { useEffect, useRef, useState } from "react";

import NoImage from "assests/images/No-image.png";
import Filter from "./Filter";

import Popup from "components/Search/Popup/popup";
import LastSection from "./LastSection";

const ArticleModal = ({ closeModal, articleInfo, options, currency, currencyRate }) => {
    const [modalFilter, setModalFilter] = useState("1d");

    const modalRef = useRef();
    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="flex fixed items-center justify-center w-full h-full overflow-hidden"
            style={{
                top: "50%",
                left: "50%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
        >
            <div ref={modalRef} className="bg-white rounded-md mx-4">
                <div className="flex items-center p-3 border-b mb-2.5">
                    <div
                        className="border rounded overflow-hidden min-w-[85px] h-[53px] mr-2"
                        style={{
                            backgroundImage: `url(${articleInfo.thumbnail || NoImage})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>
                    <div className="flex flex-col ">
                        <a
                            href={articleInfo.url}
                            rel="noreferrer"
                            target="_blank"
                            className="text-[13px] font-bold lg:hidden overflow-hidden leading-4 h-[32px] line-clamp-2"
                        >
                            {articleInfo.title?.slice(0, 75)}...
                        </a>
                        <div className="flex items-center text-[10px]">
                            1h <span className="px-0.5 -mt-5 text-3xl text-[#767778]">.</span>
                            <p className="font-medium whitespace-nowrap capitalize">
                                {articleInfo.publisherName?.slice(0, 18)}
                            </p>
                        </div>
                    </div>
                </div>
                <Filter
                    articleInfo={articleInfo}
                    setModalFilter={setModalFilter}
                    modalFilter={modalFilter}
                />

                <Popup
                    url={articleInfo.url}
                    price={articleInfo.price}
                    priceDirection={"up"}
                    options={options}
                    currency={currency}
                    currencyRate={currencyRate}
                    hideFilters={true}
                    modalFilter={modalFilter}
                />
                <div className="mb-3"></div>
                <LastSection />
            </div>
        </div>
    );
};

export default ArticleModal;
