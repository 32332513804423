// import analyticsRust from "../../analyticsRust";
import Component from "../index";

const Filters = ({
    timeFilter,
    setTimeFilter,
    price,
    options,
    priceDirection,
    url,
    currency,
    currencyRate,
}) => {
    // let _price = parseFloat(price)?.toFixed(2);
    // _price = String(price);
    // let priceAfterDot = _price.split(".")[1];

    // if (priceAfterDot) {
    //     if (priceAfterDot.length === 1) {
    //         priceAfterDot = priceAfterDot + "0";
    //     }
    // }

    return (
        <div className="qx-widget-more-section absolute -top-6 right-0">
            <div className="qx-widget-filters text-[10px] filter-component font-medium mr-4">
                <div></div>
                {/* <Component
                    url={url}
                    price={price}
                    options={options}
                    currency={currency}
                    currencyRate={currencyRate}
                /> */}
                <div className="flex items-center justify-center">
                    {/* <li
                    onClick={() => setTimeFilter("1h")}
                    className={timeFilter === "1h" ? "active" : ""}
                >
                    1H
                </li> */}
                    <li
                        onClick={() => {
                            setTimeFilter("6h");
                        }}
                        className={timeFilter === "6h" ? "active" : ""}
                    >
                        6H
                    </li>
                    <li
                        onClick={() => {
                            setTimeFilter("1d");
                        }}
                        className={timeFilter === "1d" ? "active" : ""}
                    >
                        1D
                    </li>
                    <li
                        onClick={() => {
                            setTimeFilter("30d");
                        }}
                        className={timeFilter === "30d" ? "active" : ""}
                    >
                        1M
                    </li>
                </div>
            </div>
        </div>
    );
};

export default Filters;
