import Popup from "./popup";

const Modal = ({
    options,
    articlePrice,
    priceDirection,
    url,
    currency,
    currencyRate,
    openGraphModal,
}) => {
    return (
        <Popup
            url={url}
            price={articlePrice}
            options={options}
            priceDirection={priceDirection}
            currency={currency}
            currencyRate={currencyRate}
            openGraphModal={openGraphModal}
        />
    );
};

export default Modal;
