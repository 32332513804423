import { useEffect, useState } from "react";

import Filters from "./Filters";

import GraphPage from "./Graph";
import GraphPage2 from ".//CandleGraph/Graph2";
import { chandlechartAPI } from "./utils";

const Popup = ({
    url,
    price,
    options,
    priceDirection,
    currency,
    currencyRate,
    hideFilters,
    modalFilter,
    openGraphModal,
}) => {
    const [graphData, setGraphData] = useState([]);
    const [timeFilter, setTimeFilter] = useState(modalFilter || "1d");
    const [range, setRange] = useState("");
    const [valueMarginTop, setMarginTop] = useState(0);

    const getGraphData = async () => {
        setGraphData([]);
        let _timeFilter = modalFilter ? modalFilter : timeFilter;

        // Storing response
        const response = await fetch(chandlechartAPI, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                currency: options.currency,
                url: url,
                duration: _timeFilter,
            }),
        });
        // Storing data in form of JSON
        var data = await response.json();
        if (data.ok) {
            let _data = data;
            _data = _data.data;
            setGraphData(_data);
        }
    };

    useEffect(() => {
        getGraphData();
    }, [timeFilter, url, modalFilter]);

    useEffect(() => {
        let min = range[0];
        let max = range[1];
        if (min !== "Nan" && max !== "Nan") {
            const current = graphData[0]?.low;
            const height = 84;

            const range = max - min;
            const rangePerPixel = height / range;
            const diff = max - current;
            const spacing = diff * rangePerPixel;

            setMarginTop(spacing);
        }
    }, [range]);

    // useEffect(() => {
    //     analyticsRust.graphEvent({
    //         articleUrl: url,
    //         subtype: "open",
    //         price: price,
    //         currency: options.currency,
    //     });
    // }, [url]);

    return (
        <div className="relative">
            {/* price and graph section */}
            {!hideFilters && (
                <Filters
                    options={options}
                    timeFilter={timeFilter}
                    setTimeFilter={setTimeFilter}
                    price={price}
                    priceDirection={priceDirection}
                    url={url}
                    currency={currency}
                    currencyRate={currencyRate}
                />
            )}
            <div onClick={() => openGraphModal()}>
                {!hideFilters && (
                    <GraphPage
                        priceDirection={priceDirection}
                        graphData={graphData}
                        setRange={setRange}
                        timeFilter={timeFilter}
                    />
                )}
            </div>

            {hideFilters && (
                <GraphPage2
                    priceDirection={priceDirection}
                    graphData={graphData}
                    setRange={setRange}
                    timeFilter={modalFilter}
                />
            )}

            {/* <Accuracy url={url} price={price} options={options} /> */}
            {/* demand section */}

            {/* <BrandSection url={url} price={price} options={options} /> */}
        </div>
    );
};

export default Popup;
