import { useEffect, useState } from "react";

const BatteryBar = ({ sizeDown }) => {
    const postfix = Math.random().toString().substr(2, 8);
    const newId = `${postfix}-demand`;

    const [barPercentage] = useState(Math.random() * 10);

    useEffect(() => {
        // const barPercentage = Math.random() * 10;

        const demandBarDiv = document.getElementById(newId);

        // Function to add demand bars
        for (var i = 0; i < 10; i++) {
            const demandBar = document.createElement("div");
            demandBar.classList.add("barSingle");
            demandBar.style.width = "7px";
            demandBar.style.height = sizeDown ? "2px" : "3px";
            demandBar.style.listStyle = "7px";
            demandBar.style.marginBottom = "1px";

            const barNumber = Math.round(barPercentage);

            if (i < barPercentage) {
                if (barNumber < 2) {
                    demandBar.style.backgroundColor = "#E74839";
                } else if (barNumber > 2 && barNumber < 5) {
                    demandBar.style.backgroundColor = "#E76339";
                } else if (barNumber > 5 && barNumber < 8) {
                    demandBar.style.backgroundColor = "#87A900";
                } else {
                    demandBar.style.backgroundColor = "#24A900";
                }
                // demandBar.style.backgroundColor = "#25CA83";
            } else {
                demandBar.style.backgroundColor = "#ACACAC";
            }

            demandBarDiv.appendChild(demandBar);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id={newId} className="qx-widget-demandBarDiv">
            <span
                className={sizeDown ? "text-[14px] font-medium " : "text-[10px] font-medium pt-1"}
            >
                {barPercentage.toFixed(1)}
            </span>
        </div>
    );
};

export default BatteryBar;
