import "./style.scss";

const Loader = () => {
    return (
        <div className="loader loader--style8" title="7">
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="34px"
                height="40px"
                viewBox="0 0 24 30"
                style={{ enableBackground: " new 0 0 60 60" }}
                // xml:space="preserve"
            >
                <rect x="0" y="10" width="4" height="10" fill="#0085FF" opacity="0.2">
                    <animate
                        attributeName="opacity"
                        attributeType="XML"
                        values="0.2; 1; .2"
                        begin="0s"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="height"
                        attributeType="XML"
                        values="10; 20; 10"
                        begin="0s"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="y"
                        attributeType="XML"
                        values="10; 5; 10"
                        begin="0s"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#0085FF" opacity="0.2">
                    <animate
                        attributeName="opacity"
                        attributeType="XML"
                        values="0.2; 1; .2"
                        begin="0.15s"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="height"
                        attributeType="XML"
                        values="10; 20; 10"
                        begin="0.15s"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="y"
                        attributeType="XML"
                        values="10; 5; 10"
                        begin="0.15s"
                        dur="0.6s"
                        repeatCount="indefinite"
                    />
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#0085FF" opacity="0.1">
                    <animate
                        attributeName="opacity"
                        attributeType="XML"
                        values="0.2; 1; .2"
                        begin="0.9s"
                        dur="1.3s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="height"
                        attributeType="XML"
                        values="10; 20; 10"
                        begin="0.9s"
                        dur="1.3s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="y"
                        attributeType="XML"
                        values="10; 5; 10"
                        begin="0.9s"
                        dur="1.3s"
                        repeatCount="indefinite"
                    />
                </rect>
            </svg>
        </div>
    );
};

export default Loader;
