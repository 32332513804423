// Function to convert currency
export const converterCurrency = (value, rate) => {
    let a = value;

    if (rate) {
        a = a / rate;
    }

    return a.toFixed(2);
};
