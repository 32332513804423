const Close = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.8735 10.625L17.4516 5.05577C17.6183 4.88904 17.712 4.66291 17.712 4.42713C17.712 4.19134 17.6183 3.96521 17.4516 3.79848C17.2849 3.63175 17.0587 3.53809 16.8229 3.53809C16.5872 3.53809 16.361 3.63175 16.1943 3.79848L10.625 9.3766L5.05576 3.79848C4.88903 3.63175 4.6629 3.53809 4.42711 3.53809C4.19132 3.53809 3.96519 3.63175 3.79846 3.79848C3.63174 3.96521 3.53807 4.19134 3.53807 4.42713C3.53807 4.66291 3.63174 4.88904 3.79846 5.05577L9.37659 10.625L3.79846 16.1943C3.71548 16.2766 3.64961 16.3746 3.60465 16.4824C3.5597 16.5903 3.53656 16.7061 3.53656 16.823C3.53656 16.9398 3.5597 17.0556 3.60465 17.1635C3.64961 17.2714 3.71548 17.3693 3.79846 17.4516C3.88078 17.5346 3.9787 17.6005 4.0866 17.6454C4.1945 17.6904 4.31023 17.7135 4.42711 17.7135C4.544 17.7135 4.65973 17.6904 4.76762 17.6454C4.87552 17.6005 4.97345 17.5346 5.05576 17.4516L10.625 11.8735L16.1943 17.4516C16.2766 17.5346 16.3745 17.6005 16.4824 17.6454C16.5903 17.6904 16.7061 17.7135 16.8229 17.7135C16.9398 17.7135 17.0556 17.6904 17.1635 17.6454C17.2713 17.6005 17.3693 17.5346 17.4516 17.4516C17.5346 17.3693 17.6004 17.2714 17.6454 17.1635C17.6904 17.0556 17.7135 16.9398 17.7135 16.823C17.7135 16.7061 17.6904 16.5903 17.6454 16.4824C17.6004 16.3746 17.5346 16.2766 17.4516 16.1943L11.8735 10.625Z"
                fill="#888888"
            />
        </svg>
    );
};

export default Close;
