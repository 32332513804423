const Twitter = () => {
    return (
        <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9973 1.87236C16.4091 2.13367 15.7761 2.30965 15.1122 2.38911C15.79 1.98275 16.3104 1.33961 16.5552 0.573288C15.9217 0.949785 15.2183 1.22229 14.4712 1.37001C13.8723 0.732208 13.0185 0.333313 12.0751 0.333313C10.2625 0.333313 8.79278 1.80304 8.79278 3.61566C8.79278 3.8727 8.82211 4.12334 8.8781 4.36385C6.14984 4.22733 3.7314 2.92026 2.11236 0.934321C1.82972 1.41907 1.66814 1.98276 1.66814 2.5843C1.66814 3.72285 2.24675 4.72756 3.12826 5.3163C2.58965 5.29924 2.0841 5.15151 1.64094 4.90567V4.94673C1.64094 6.53751 2.7731 7.86378 4.27375 8.16615C3.99858 8.24081 3.70847 8.28134 3.4093 8.28134C3.19759 8.28134 2.99174 8.26054 2.79123 8.22215C3.20879 9.52602 4.42147 10.4753 5.8576 10.5019C4.73451 11.3818 3.31918 11.9071 1.78119 11.9071C1.51562 11.9071 1.25431 11.8917 0.997803 11.8607C2.45046 12.7918 4.17563 13.3358 6.02931 13.3358C12.0666 13.3358 15.3681 8.33413 15.3681 3.99695C15.3681 3.85457 15.3655 3.71272 15.3585 3.57247C16.0006 3.10851 16.5574 2.53043 16.9973 1.87236Z"
                fill="black"
            />
        </svg>
    );
};

export default Twitter;
