import { withRouter } from "react-router-dom";
import Close from "shared/icons/Close";

import Logo from "assests/images/Logo.png";

import SearchLogo from "shared/icons/SearchLogo";

const SearchBar = ({ history, setSearchTerm, searchTerm, handleSubmit, scrollPosition }) => {
    // const [user, setUser] = useState(localStorage.getItem("dummyUser") || null);

    // const handleUser = () => {
    //     setUser("a");
    //     localStorage.setItem("dummyUser", "dummy");
    // };

    const setClass = () => {
        let _class =
            "flex flex-col lg:flex-row items-center justify-between pl-0 lg:px-[71px] lg:py-2 lg:pt-4 w-full z-50 bg-white";

        // if (scrollPosition > 70) {
        //     _class = _class + " fixed py-3";
        // }

        return _class;
    };

    const handleSearch = (e) => {
        e.preventDefault();
        handleSubmit(1);
    };

    const handleBack = () => {
        history.push("/");
        window.location.reload();
    };

    return (
        <div
            className={setClass()}
            // style={scrollPosition > 70 ? { boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" } : {}}
        >
            <div className="w-full flex items-center lg:max-w-[800px] pt-6 pb-3">
                <div onClick={() => handleBack()} className="mx-2 cursor-pointer">
                    <img src={Logo} alt="logo-img" className="mx-2 w-6 h-6 lg:w-10 lg:h-10" />
                </div>

                <form
                    className="w-full flex items-center justify-center mb-1 mr-3 lg:pr-0 "
                    onSubmit={(e) => handleSearch(e)}
                >
                    <div className="flex items-center w-full rounded-xl overflow-hidden ">
                        <div className="relative w-full flex ">
                            <input
                                type="search"
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                                value={searchTerm}
                                autoFocus={false}
                                placeholder="Search Valued content here"
                                className="py-3 pl-4 pr-8 bg-[#F2F2F2] w-full border-none focus:outline-none text-black text-sm font-medium"
                            />
                            {searchTerm ? (
                                <span
                                    className="absolute top-3 right-0 cursor-pointer pr-3"
                                    onClick={() => setSearchTerm("")}
                                >
                                    <Close />
                                </span>
                            ) : (
                                <button
                                    onClick={() => handleSearch()}
                                    className="pt-2.5 pl-2.5 px-3 flex rounded-r-xl bg-[#F2F2F2]"
                                >
                                    <SearchLogo color={"#E0E0E0"} />
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withRouter(SearchBar);
