import BatteryBar from "./BatteryBar";
// import analytics from "../../analytics";

const DemandSection = ({ sizeDown }) => {
    return (
        <div
            className={
                sizeDown
                    ? "qx-widget-demand-left overflow-hidden flex flex-col-reverse h-[52px]"
                    : "qx-widget-demand-left overflow-hidden flex flex-col-reverse h-[60px]"
            }
            style={{ width: "20px" }}
            // onClick={() =>
            //     analytics.trackArticleHover("DemandClicked", url, price, options.currency)
            // }
        >
            <BatteryBar sizeDown={sizeDown} />
        </div>
    );
};

export default DemandSection;
