import { useState } from "react";
// import CurrencyFilter from "./CurrencyFilter";
// import SortingFilter from "./Sorting";
// import ArticleFilter from "./ArticleFilter";

import ChevronDown2 from "shared/icons/ChevronDown2";

const SearchTypes = ({ scrollPosition, currency, setCurrency }) => {
    const [_filter, setFilterType] = useState("currency");
    const [sorting, setSorting] = useState("");
    const [filter, setFilter] = useState("");

    const setClass = () => {
        let _class = "flex flex-col w-full items-start justify-start bg-[#fff]";

        return _class;
    };

    const filterClass = (type) => {
        let _class = "text-black cursor-pointer px-0.5 pb-1 ml-4";

        if (_filter === type) {
            _class =
                "text-black text-center pb-1 border-b-2 border-[#0085FF] cursor-pointer px-0.5 ml-4";
        }

        return _class;
    };

    return (
        <div className={setClass()}>
            <div className="flex flex-row w-full items-center justify-between lg:max-w-[860px]">
                <div className="flex items-center justify-start w-full lg:max-w-[860px] lg:ml-20 list-none font-semibold text-[12px] lg:text-base border-b lg:border-none">
                    <li onClick={() => setFilterType("filter")} className={filterClass("filter")}>
                        Filter
                    </li>
                    <li
                        // onClick={() => setFilterType("sorting")}
                        className={filterClass("sorting")}
                    >
                        Sorting
                    </li>
                </div>

                {/* <CurrencyFilter setCurrency={setCurrency} currency={currency} />

                {_filter === "sorting" && (
                    <SortingFilter setSorting={setSorting} sorting={sorting} />
                )}

                {_filter === "filter" && <ArticleFilter setFilter={setFilter} filter={filter} />} */}
            </div>

            {_filter === "filter" && (
                <div className="flex items-center text-xs font-medium ml-4 py-2.5 lg:ml-24">
                    <p className="flex items-center mr-4">
                        ₹20 - ₹110
                        <span className="ml-1">
                            <ChevronDown2 />
                        </span>
                    </p>
                    <p className="flex items-center mr-4">
                        English
                        <span className="ml-1">
                            <ChevronDown2 />
                        </span>
                    </p>
                    <p className="flex items-center mr-4">
                        60 mins
                        <span className="ml-1">
                            <ChevronDown2 />
                        </span>
                    </p>
                    <p className="flex items-center">
                        All
                        <span className="ml-1">
                            <ChevronDown2 />
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default SearchTypes;
