import * as d3 from "d3";
import "regenerator-runtime/runtime";
import { useEffect } from "react";

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/candlestick-chart
function CandlestickChart(
    data,
    {
        time = (d) => d.time, // given d in data, returns the (temporal) x-value
        open = (d) => d.open, // given d in data, returns a (quantitative) y-value
        close = (d) => d.close, // given d in data, returns a (quantitative) y-value
        high = (d) => d.high, // given d in data, returns a (quantitative) y-value
        low = (d) => d.low, // given d in data, returns a (quantitative) y-value
        width = 300, // outer width, in pixels
        height = 100, // outer height, in pixels
        xDomain, // array of x-values (defaults to every weekday)
        yDomain, // [ymin, ymax]
        stroke = "#868383", // stroke color for the daily rule
        strokeLinecap = "", // stroke line cap for the rules
        colors = ["#3D9226", "#999999", "#E85043"], // [up, no change, down]
        setRange,
        tickColor = "red",
    } = {}
) {
    // Compute values.
    const X = d3.map(data, time);
    const Yo = d3.map(data, open);
    const Yc = d3.map(data, close);
    const Yh = d3.map(data, high);
    const Yl = d3.map(data, low);
    const I = d3.range(X.length);

    // Compute default domains and ticks.
    if (xDomain === undefined) xDomain = (d3.min(X), d3.max(X));
    if (yDomain === undefined) yDomain = [d3.min(Yl) * 0.9, d3.max(Yh) + 1];
    // if (xTicks === undefined) xTicks = (d3.min(xDomain), d3.max(xDomain), 2);

    setRange(yDomain);

    const yScale = d3
        .scaleLinear()
        .domain([
            d3.min(
                data.map(function (x) {
                    return d3.min([x.low * 0.9, x.high, x.close, x.open]);
                })
            ),
            d3.max(
                data.map(function (x) {
                    return d3.max([x.low, x.high * 1.1, x.close, x.open]);
                })
            ),
        ])
        .range([height, 0]);

    const xScale = d3
        .scaleLinear()
        .domain([
            d3.min(
                data.map(function (d) {
                    return d.time;
                })
            ),
            d3.max(
                data.map(function (d) {
                    return d.time;
                })
            ),
        ])
        .range([0, width]);

    const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic; width: auto");

    // add the Y Axis
    const yAxis = svg
        .append("g")
        .attr("transform", `translate(28,-2)`)
        .call(d3.axisLeft(yScale).ticks(5))
        .call((g) =>
            g
                .selectAll(".tick line")
                .clone()
                .attr("stroke-opacity", 0.3)
                .attr("x2", width - 30)
                .style("stroke-dasharray", "3, 3")
        );

    // Customize x-axis ticks
    yAxis.selectAll(".tick").attr("color", "#585858").style("font-size", "9px");

    // Hide the small line shown with each tick
    yAxis.selectAll(".tick line:first-child").style("display", "none");
    yAxis.select(".domain").style("display", "none");

    // add the X Axis
    svg.append("g")
        .attr("transform", `translate(28,${height - 4})`)
        .call(d3.axisBottom(xScale).ticks(5));

    svg.selectAll(".domain").style("color", "#fff");

    const g = svg
        .append("g")
        .attr("stroke", stroke)
        .attr("stroke-linecap", strokeLinecap)
        .attr("transform", `translate(28,0.8)`)
        .selectAll("g")
        .data(I)
        .join("g")
        // .attr("transform", (i) => `translate(${console.log("first - ", X[i])},0)`);
        // .attr("transform", (i) => `translate(${xScale(X[i])},0)`);
        .attr("transform", (i) => {
            if (data.length > 60) {
                return `translate(${i * 3 + 7},0)`;
            } else if (data.length > 25) {
                return `translate(${i * 8 + 7},0)`;
            } else {
                return `translate(${i * 10 + 13},0)`;
            }
        });

    g.append("line")
        .attr("y1", (i) => yScale(Yl[i]))
        .attr("y2", (i) => yScale(Yh[i]));

    g.append("line")
        .attr("y1", (i) => yScale(Yo[i]))
        .attr("y2", (i) => yScale(Yc[i]))
        .attr("stroke-width", () => {
            return data.length > 60 ? "2" : "5";
        })
        .attr("stroke", (i) => colors[1 + Math.sign(Yo[i] - Yc[i])]);

    return svg.node();
}

const Graph = ({ priceDirection, graphData, setRange, timeFilter }) => {
    const id = parseInt(Math.random() * 1000000).toString();

    useEffect(() => {
        const svgGraph = document.querySelector(`div[class=graph-${id}] svg`);

        if (svgGraph) {
            svgGraph.remove();
        }

        const chart = CandlestickChart(graphData, {
            time: (d) => d.time,
            high: (d) => d.high,
            low: (d) => d.low,
            open: (d) => d.open,
            close: (d) => d.close,
            // width: "90%",
            height: 100,
            timeFilter,
            setRange,
        });
        const elm = document.querySelector(`div[class=graph-${id}]`);
        elm.appendChild(chart);
    }, [priceDirection, graphData, timeFilter]);

    return (
        <div
            className={`graph-${id}`}
            style={{
                width: "100%",
                height: "100px",
                position: "relative",
                color: "#959090",
                marginLeft: "10px",
            }}
        >
            {/* <span className="qx-widget-chart-y-label">Price</span>
            <span className="qx-widget-chart-x-label">Time</span> */}
        </div>
    );
};

export default Graph;
