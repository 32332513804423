import React, { useEffect, useRef } from "react";

import code from "assests/images/code.png";

const QRModal = ({ closeModal }) => {
    const modalRef = useRef();
    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="flex fixed items-center justify-center w-full h-full overflow-hidden"
            style={{
                top: "50%",
                left: "50%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
        >
            <div ref={modalRef}>
                <img src={code} alt="img-qrcode" width={212} height={212} />
            </div>
        </div>
    );
};

export default QRModal;
