import { Link } from "react-router-dom";

import Facebook from "shared/icons/Facebook";
import Instagram from "shared/icons/Instagram";
import Linkedin from "shared/icons/Linkedin";
import Twitter from "shared/icons/Twitter";

const Footer = () => {
    return (
        <div
            className="flex flex-col  lg:py-2"
            style={{
                boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div className="flex flex-col lg:flex-row w-full items-center justify-between text-sm py-3 lg:px-16">
                <div className="flex flex-row items-center justify-between w-full max-w-[350px] lg:w-auto lg:max-w-max px-8 lg:pb-0 lg:px-0 list-none text-[10px] lg:text-sm font-medium text-gray2">
                    <li className="cursor-pointer lg:pt-0 hover:text-blue1">
                        <a href="https://kunato.ai/" rel="noreferrer" target="_blank">
                            Kunato.Ai
                        </a>
                    </li>
                    <li className="cursor-pointer lg:pl-12 lg:pt-0 hover:text-blue1">
                        <Link to="/"> Disclaimers</Link>
                    </li>
                    <li className="cursor-pointer lg:pl-12 lg:pt-0 hover:text-blue1">
                        <Link to="/"> Terms of Service</Link>
                    </li>
                    <li className="cursor-pointer lg:pl-12 lg:pt-0 hover:text-blue1">
                        <Link to="/">Privacy</Link>
                    </li>
                </div>

                <div className="hidden lg:flex items-center justify-around list-none w-[200px]">
                    <a
                        href="https://www.facebook.com/KunatoAi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Facebook />
                    </a>
                    <a
                        href="https://in.linkedin.com/company/kunato"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Linkedin />
                    </a>
                    <a
                        href="https://twitter.com/ai_kunato"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Twitter />
                    </a>
                    <a
                        href="https://www.instagram.com/kunato.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Instagram />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
