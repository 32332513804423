const Linkedin = () => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.51546 1.5223C3.51546 2.35233 2.88346 3.02406 1.89512 3.02406C0.945282 3.02406 0.31328 2.35233 0.332839 1.5223C0.31328 0.651925 0.945267 -0.000244141 1.91405 -0.000244141C2.88345 -0.000244141 3.49652 0.651925 3.51546 1.5223ZM0.412301 13.8787V4.21044H3.41705V13.8781H0.412301V13.8787Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.82556 7.2953C5.82556 6.08937 5.78582 5.0613 5.74609 4.21109H8.356L8.49474 5.53561H8.55404C8.9495 4.92255 9.93784 3.99411 11.5392 3.99411C13.5159 3.99411 14.9988 5.29906 14.9988 8.1449V13.8793H11.994V8.5214C11.994 7.27513 11.5594 6.42554 10.472 6.42554C9.6414 6.42554 9.14754 6.99886 8.95012 7.55201C8.87066 7.75005 8.83154 8.02632 8.83154 8.30381V13.8793H5.82677V7.2953H5.82556Z"
                fill="black"
            />
        </svg>
    );
};

export default Linkedin;
